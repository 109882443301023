<template>
  <div class="md:grid grid-cols-1 gap-2 sm2:gap-4 md:grid-cols-2 md:gap-10">
    <!-- video embed -->
    <div class="rounded lg:shadow shadow-none overflow-hidden">
      <iframe
        class="w-full"
        height="315"
        src="https://www.youtube.com/embed/F50eCcTCeKk"
        title="Donations for Vacations"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>

    <!-- hero -->
    <div>
      <!-- headline -->
      <h2 class="text-4xl font-medium leading-tight pt-5">
        Donations for Vacations
      </h2>
      <!-- subheadline -->
      <h3 class="text-xl font-bold leading-snug mt-4">
        The simple way to reach your donors online, find sustaining members, and
        receive donations year round
      </h3>
      <!-- social proof -->
      <div class="font-semibold mt-4 flex items-center flex-col sm:flex-row">
        <div class="flex items-center mr-3">
          <i class="mdi mdi-star text-2xl text-pink-500"></i>
          <i class="mdi mdi-star text-2xl text-pink-500"></i>
          <i class="mdi mdi-star text-2xl text-pink-500"></i>
          <i class="mdi mdi-star text-2xl text-pink-500"></i>
          <i class="mdi mdi-star text-2xl text-pink-500"></i>
        </div>
        <h4
          class="text-xl font-medium text-gray-800"
          style="margin-bottom: 2px"
        >
          1000+ Donations Received
        </h4>
      </div>
      <!-- cta -->
      <div class="mt-8">
        <button
          @click="getStartedDialog = true"
          class="
            uppercase
            bg-blue-600
            text-blue-50
            hover:bg-blue-700
            font-semibold
            cursor-pointer
            tracking-wide
            transition
            rounded-full
            px-5
            py-3
            space-x-3
            inline-flex
            items-center
            justify-between
            text-lg
          "
        >
          <span>Get Donations Year Round <i>(Risk Free)</i></span>
          <i class="mdi mdi-check text-xl"></i>
        </button>
      </div>
    </div>

    <!-- blurbs -->
    <div
      class="
        col-span-2
        grid grid-cols-1
        gap-8
        pt-6
        pb-8
        sm:grid-cols-2
        lg:grid-cols-3
        md:gap-12
      "
    >
      <!-- blurb items  -->
      <div
        class="mt-6 sm:mt-0 space-y-4"
        v-for="(item, index) in blurbs"
        :key="index"
      >
        <div class="flex items-center justify-center">
          <i class="mdi mdi-check-circle-outline text-2xl text-green-700"></i>
        </div>
        <h5 class="text-center font-semibold text-xl">
          {{ item.title }}
        </h5>
        <div v-html="item.text" />
      </div>
      <!-- quick and easy setup process -->
      <div class="mt-6 sm:mt-0 space-y-4">
        <div class="flex items-center justify-center">
          <i class="mdi mdi-check-circle-outline text-2xl text-green-700"></i>
        </div>
        <h5 class="text-center font-semibold text-xl">
          Quick and Easy Setup Process
        </h5>
        <div class="space-y-6 text-center">
          <p>Get up and running very quickly.</p>
          <button
            @click="getStartedDialog = true"
            class="
              uppercase
              bg-blue-600
              text-blue-50
              hover:bg-blue-700
              font-semibold
              cursor-pointer
              text-sm
              tracking-wide
              transition
              rounded-full
              px-4
              py-2
              space-x-3
              inline-flex
              items-center
              justify-between
            "
          >
            <span>Get Started Risk Free</span>
            <i class="mdi mdi-check text-xl"></i>
          </button>
        </div>
      </div>
      <!--  -->
    </div>

    <!-- testimonials -->
    <div
      class="
        col-span-2
        py-16
        px-10
        bg-gray-100
        rounded-lg
        border border-gray-200
        mt-6
        md:mt-0
      "
    >
      <!-- testimonial item -->
      <div class="max-w-4xl" v-for="(item, index) in testimonials" :key="index">
        <div class="font-semibold text-2xl">
          {{ item.text }}
        </div>
        <div class="mt-6 text-sm">
          <span class="block font-semibold text-gray-700">{{
            item.author.name
          }}</span>
          <span class="block">
            <a :href="item.author.website" target="_blank" class="href">{{
              item.author.organization
            }}</a>
          </span>
        </div>
      </div>
    </div>

    <!-- questions -->
    <div
      class="
        col-span-2
        grid grid-cols-1
        lg:grid-cols-3
        sm:grid-cols-2
        md:gap-12
        gap-8
        py-12
      "
    >
      <!-- question items -->
      <div v-for="(item, index) in questions" :key="index">
        <div>
          <h5 class="text-center font-semibold text-xl px-4">
            {{ item.title }}
          </h5>
          <p class="mt-4">
            {{ item.text }}
          </p>
        </div>
      </div>

      <!-- How do I get started? -->
      <div>
        <div>
          <h5 class="text-center font-semibold text-xl px-4">
            How do I get started?
          </h5>
          <p class="mt-4 mb-7">
            Just click the button below. We'll be in touch shortly after you
            submit your request.
          </p>
          <div class="text-center">
            <button
              @click="getStartedDialog = true"
              class="
                uppercase
                bg-blue-600
                text-blue-50
                hover:bg-blue-700
                font-semibold
                cursor-pointer
                text-sm
                tracking-wide
                transition
                rounded-full
                px-4
                py-2
                space-x-3
                items-center
                justify-between
                mx-auto
                inline-flex
              "
            >
              <span>Get Started Risk Free</span>
              <i class="mdi mdi-check text-xl"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 2nd cta -->
    <div
      class="
        col-span-2
        grid grid-cols-1
        lg:grid-cols-2
        gap-12
        py-16
        px-10
        bg-gray-100
        rounded-lg
        border border-gray-200
      "
    >
      <div class="font-semibold text-2xl">
        Ready to reach your donors online and receive donations year round?
      </div>
      <div
        class="
          flex flex-col
          sm:flex-row
          items-center
          justify-center
          lg:justify-end
        "
      >
        <button
          @click="getStartedDialog = true"
          class="
            uppercase
            bg-blue-600
            text-blue-50
            hover:bg-blue-700
            font-semibold
            cursor-pointer
            tracking-wide
            transition
            rounded-full
            px-4
            py-2
            space-x-3
            items-center
            justify-between
            mx-auto
            inline-flex
          "
        >
          <span>Get Started Risk Free</span>
          <i class="mdi mdi-check text-xl"></i>
        </button>

        <router-link
          :to="'/' + $store.state.selectedPartner.id + '/contact'"
          class="
            mt-6
            sm:mt-0
            inline-block
            cursor-pointer
            font-medium
            hover:text-gray-600
            rounded-lg
            px-5
            py-3
            transition
            duration-200
            ease-in-out
          "
          >I Have Questions</router-link
        >
      </div>
    </div>

    <!-- founders note/mission -->
    <div class="col-span-2 py-12">
      <div class="max-w-2xl">
        <h5 class="text-xl font-semibold">Our Mission</h5>
        <p class="mt-4">
          Donors are spending more time online than ever before. We created the
          donations for vacations program to help our partners find sustaining
          members and receive donations year round.
        </p>
        <p class="mt-4">
          We aim to help our partner organizations maximize their donations
          received through continued support and innovation.
          <router-link
            :to="'/' + $store.state.selectedPartner.id + '/contact'"
            class="href"
            >Submit any feedback or suggestions you have here.</router-link
          >
          We'd love to hear from you.
        </p>
        <p class="mt-4">Thank you</p>
        <p class="mt-4">~ Auction Vacations</p>
      </div>
    </div>

    <!-- get started dialog -->
    <transition name="fade">
      <!-- wrapper -->
      <div
        @click="close"
        v-if="getStartedDialog"
        class="
          fixed
          inset-0
          bg-gray-900 bg-opacity-80
          close
          flex
          items-center
          justify-center
        "
      >
        <!-- card -->
        <div class="bg-gray-50 rounded shadow p-4 max-w-lg w-full relative">
          <!-- close btn -->
          <button
            @click="getStartedDialog = false"
            class="
              cursor-pointer
              hover:bg-gray-600 hover:bg-opacity-25
              transition
              rounded-full
              h-10
              w-10
              flex
              items-center
              justify-center
              text-center
              close
              absolute
              top-2
              right-2
            "
          >
            <i
              class="mdi mdi-close-circle-outline text-2xl text-gray-600"
              style="margin-left: 1px; margin-top: 1px"
            ></i>
          </button>
          <!-- title -->
          <h3 class="text-2xl font-semibold uppercase mr-10">
            Get Started Risk Free
          </h3>
          <!-- subtitle -->
          <div class="font-medium my-6 text-lg">
            Please enter your contact details below. We'll be in touch shortly.
          </div>
          <!-- form error -->
          <div
            class="rounded-md bg-red-50 p-4 border border-red-300"
            v-if="error"
          >
            <div class="flex">
              <div class="flex-shrink-0">
                <i
                  class="mdi mdi-alert-circle-outline text-2xl text-red-600"
                ></i>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  The form did not submit successfully
                </h3>
                <div class="mt-2 text-sm text-red-700">
                  <p>
                    We have not received your contact request. Please email us
                    directly at
                    <a
                      href="mailto:booking@silentauctionvacations.com"
                      class="href"
                      >booking@silentauctionvacations.com.</a
                    >
                    Thank you.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- form success -->
          <div
            class="rounded-md bg-green-50 p-4 border border-green-300"
            v-if="success"
          >
            <div class="flex">
              <div class="flex-shrink-0">
                <i
                  class="mdi mdi-check-circle-outline text-2xl text-green-600"
                ></i>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-green-800">
                  Form Successfully Submitted
                </h3>
                <div class="mt-2 text-sm text-green-700">
                  <p>
                    Please keep an eye on your inbox for our response within 3
                    business days. Thank you.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- form -->
          <div class="space-y-6" v-if="!error && !success">
            <!-- name -->
            <div>
              <input
                @blur="v$.name.$touch()"
                v-model="name"
                type="text"
                name="name"
                id="name"
                autocomplete="name"
                :disabled="loading"
                class="
                  block
                  w-full
                  shadow-sm
                  py-3
                  px-4
                  placeholder-gray-500
                  rounded-md
                  bg-gray-100
                "
                :class="
                  v$.name.$error
                    ? 'border-red-500  focus:ring-red-500 focus:border-red-500'
                    : 'border-gray-400 focus:ring-blue-500 focus:border-blue-500'
                "
                placeholder="Name *"
              />
              <p class="text-red-500 font-semibold mt-2" v-if="v$.name.$error">
                {{ v$.name.$errors[0].$message }}
              </p>
            </div>
            <!-- email -->
            <div>
              <input
                @blur="v$.email.$touch()"
                v-model="email"
                type="email"
                name="email"
                id="email"
                autocomplete="email"
                :disabled="loading"
                class="
                  block
                  w-full
                  shadow-sm
                  py-3
                  px-4
                  placeholder-gray-500
                  rounded-md
                  bg-gray-100
                "
                :class="
                  v$.email.$error
                    ? 'border-red-500  focus:ring-red-500 focus:border-red-500'
                    : 'border-gray-400 focus:ring-blue-500 focus:border-blue-500'
                "
                placeholder="Email *"
              />
              <p class="text-red-500 font-semibold mt-2" v-if="v$.email.$error">
                {{ v$.email.$errors[0].$message }}
              </p>
            </div>
            <!-- message -->
            <div>
              <textarea
                @blur="v$.message.$touch()"
                :disabled="loading"
                v-model="message"
                id="message"
                name="message"
                rows="4"
                class="
                  block
                  w-full
                  shadow-sm
                  py-3
                  px-4
                  placeholder-gray-500
                  rounded-md
                  bg-gray-100
                "
                :class="
                  v$.message.$error
                    ? 'border-red-500  focus:ring-red-500 focus:border-red-500'
                    : 'border-gray-400 focus:ring-blue-500 focus:border-blue-500'
                "
                placeholder="Message *"
              ></textarea>
              <p
                class="text-red-500 font-semibold mt-2"
                v-if="v$.message.$error"
              >
                {{ v$.message.$errors[0].$message }}
              </p>
            </div>
            <!-- submit button -->
            <div>
              <button
                :disabled="loading"
                @click="submit"
                class="
                  uppercase
                  bg-blue-600
                  text-blue-50
                  hover:bg-blue-700
                  font-semibold
                  cursor-pointer
                  text-sm
                  tracking-wide
                  transition
                  rounded-full
                  px-4
                  py-2
                  space-x-2
                  inline-flex
                  items-center
                  justify-between
                "
              >
                <span>Get Started Risk Free</span>
                <i v-if="!loading" class="mdi mdi-check text-lg"></i>
                <svg
                  v-if="loading"
                  class="animate-spin h-5 w-5 text-blue-100"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-50"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!--  -->
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import axios from "axios";
import NProgress from "nprogress";
export default {
  data: () => ({
    v$: useVuelidate(),
    toast: useToast(),
    getStartedDialog: false,
    name: "",
    email: "",
    message: "",
    loading: false,
    success: false,
    error: false,
    blurbs: [
      {
        title: "Receive Year Round Donations",
        text: "You're no longer solely dependant upon a yearly in person event to raise donations. We help you connect with your donors anytime, offering them a way to support you year round.",
      },
      {
        title: "Profit $500 - $1000 per Donation",
        text: "The travel packages displayed in your online travel store will allow your organization to receive a $500 - $1000 donation every time a donation is made.",
      },
      {
        title: "Find Sustaining Members",
        text: "Don't limit your organization to a single in-person event each year. Reach your donors where they're spending the most time, online.",
      },
      {
        title: "Completely Risk Free",
        text: "There is no risk to you to get started. We manage your travel store, handle all customer service requests, and book travel for your donor.",
      },
      {
        title: "No Hidden Costs",
        text: "There are no hidden costs. We manage everything for you and you keep the $500 - $1000 profit per donation received (less our consignment fee).",
      },
      {
        title: "Fully Managed for You",
        text: "We completely manage your travel store and answer all your donor's questions. You simply submit an invoice after a package is sold and we take it from there.",
      },
      {
        title: "Packages are Fully Transferable",
        text: "Donors can transfer their travel package to anyone with no fees. Packages also make amazing gifts and holiday presents.",
      },
      {
        title: "Amazing Value for Your Donor",
        text: "You donor receives the same unbeatable customer service and travel packages that you've always received from us.",
      },
    ],
    testimonials: [
      {
        text: `"We've worked with Auction Vacations for years and have always appreciated their outstanding customer service and high quality travel packages. I definitely recommend them!"`,
        author: {
          name: "Larry Little",
          organization: "Guardian Angel Bassett Rescue",
          website: "https://www.bassetrescue.org/",
        },
      },
    ],
    questions: [
      {
        title:
          "I already have a successful event every year. Why should I join?",
        text: "Donors are spending more time then ever online, especially in the wake of the Covid pandemic. Don't limit your organization to only a single in-person event each year. Reaching your donors online can only increase your chances of success.",
      },
      {
        title: "I don't really have an online audience. Will this work for me?",
        text: "In the wake of the Covid pandemic, we strongly encourage our partners to connect with their donors online. This can be accomplished through your website, social media, email, or even text messages. Staying on the minds of your donors can only increase your chances success.",
      },
      {
        title: "How does it work?",
        text: "It's very straighforward. We create a travel store for you and market your organization and your new store to your audience. When a donation is received, it's deposited directly into your account. You'll only need to create an invoice. We then take care of your donor as usual.",
      },
      {
        title: "Is there a complicated setup?",
        text: "Absolutely not. Setup is very quick and straightforward. Just complete our brief questionnaire and we'll have you up in running within a few days.",
      },
      {
        title: "What are my costs?",
        text: "There are no costs to you. After a donation is received, you submit an invoice to us in the same way you always have.",
      },
    ],
  }),
  computed: {
    axiosUrl() {
      if (process.env.NODE_ENV == "development") return "http://localhost:5000";
      return "https://api.silentauctionvacations.com";
    },
  },
  validations() {
    return {
      name: { required },
      email: { required, email },
      message: { required },
    };
  },
  methods: {
    close(e) {
      if (e.target.classList.contains("close")) this.getStartedDialog = false;
    },
    submit() {
      this.v$.$validate();
      // if error
      if (this.v$.$error)
        return this.toast.error("Please check form for errors");
      // submit form
      NProgress.start();
      this.loading = true;
      let data = {
        selectedPartner: this.$store.state.selectedPartner,
        name: this.name,
        email: this.email,
        message: this.message,
        env: process.env.NODE_ENV,
      };
      let url = this.axiosUrl + "/auctionvacations.org/donations-for-vacations";

      axios.post(url, data).then((res) => {
        if (res.status == 200) {
          this.toast.success("Form submitted successfully");
          this.success = true;
          this.error = false;
          this.loading = false;
          NProgress.done();
          return;
        } else {
          this.toast.error("The form did not submit successfully");
          this.success = false;
          this.error = true;
          this.loading = false;
          NProgress.done();
          return;
        }
      });
    },
  },
};
</script>